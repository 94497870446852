/** @format */

.card__layer1 {
  padding: 3rem 0 3rem;
  text-align: center;
  background-color: #fbfbfb;
  @media and screen(max-width: 960px) {
    justify-content: center;
  }
}

.card__layer2 {
  width: 100%;
  padding: 0 2.5rem;
  max-width: 1200px;
  margin: 0 auto;

  @media and screen(max-width: 960px) {
    padding: 0 2rem;
  }
}

.card__headers {
  margin-bottom: 2.3rem;
}

.card__subheading {
  font-size: 15px;
  color: #2b7bb1;
}

.card__cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  /* @media and screen(max-width: 960px) {
    justify-content: center;
  } */
}

.card__actual {
  background-color: #fff;
  min-width: 0;
  max-width: 21.5rem;
  flex: 1 0 17rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0.5rem;
  width: 100%;
  border-radius: 15px;
  box-shadow: 3px 4px 16px -9px #676d70;
}

.card__img {
  height: 6rem;
  width: 6rem;

  /* margin-bottom: 1rem; */
}

.card__img > img {
  max-width: 100%;
  max-height: 100%;
}

.card__title {
  flex: 0 1 auto;
  margin: 0;
  padding: 1.2rem 1rem 0;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 20px;
  /* font-weight: 500; */
}

.card__content {
  width: 100%;
  padding: 1rem;
  font-size: 0.6rem;
  line-height: 1rem;
}
