/** @format */

/* .SocialMedia {
  color: black;
} */

.SocialMedia {
  position: fixed;
  top: 80%;
  z-index: 100;
  -webkit-transform: translateY(-70%);
  -ms-transform: translateY(-70%);
  transform: translateY(-70%);
}
.SocialIcons {
  display: flex;
  flex-direction: column;
  background-color: #abf1fd;
  padding: 10px;
  /* opacity: 0.9; */
  border-radius: 5px;
  /* justify-content: space-between; */
  align-items: center;
  margin-left: 8px;
  /* width: 80px; */
}

.SocialIconLink {
  /* color: white;
  background-color: #0f4473; */
  color: rgb(27, 43, 68);
  width: 100%;
  height: auto;

  text-align: center;
  font-size: 25px;
  border-radius: 5px;
  /* margin-bottom: 6px; */
  /* padding: 5px; */
  @media screen and (max-width: 700px) {
    font-size: 30px;
  }
}
