/** @format */

.contact__items {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}

.contact__items_icon {
  color: white;
  font-size: 22px;
  margin-right: 10px;
}

.contact__items > small {
  font-size: 15px;
  font-weight: 300;
}

.contact__items > strong {
  font-weight: 500;
  margin-right: 16px;
}

.contact__items > small {
  padding-top: 3px;
}

.certificates {
  display: flex;

  flex: auto;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  flex-wrap: wrap;

  color: white;
  border-bottom: 1px solid white;
}

.certificates > h2 {
  font-weight: 350;
  font-size: 1.2rem;
  padding-bottom: 15px;
  color: rgb(218, 216, 216);
}

.certi_images {
  /* display: flex;
  flex-direction: row;
  flex: 0.5; */
  display: flex;
  gap: 40px;
  padding-bottom: 20px;
}
