/** @format */

.rec-carousel-item:focus {
  outline: none;
  box-shadow: inset 0 0 1px 1px lightgrey;
  color: #d3d4d4;
}

.rec-carousel-wrapper {
  margin-top: 5.7rem !important;
  margin-bottom: 1.8rem !important;
}

.gRoCBJ {
  margin: 0 30px !important;
}
