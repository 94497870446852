/** @format */

.product {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product__title {
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  margin-top: 25px;
}

.product__description {
  font-size: 15px;
  text-align: center;
  max-width: 825px;
  @media screen and (max-width: 770px) {
    max-width: 465px;
  }
}

.product__description > p {
  background-color: rgb(214, 221, 221);
  color: #424a4d;
  padding: 30px;
  width: 100%;
  border-radius: 10px;
}

.product__cardWrapper {
  display: flex;
  margin-bottom: 60px;
  height: 100%;
  border-radius: 15px;
  flex-direction: column;
  grid-gap: 60px;
  padding: 15px;
  margin: 10px;
}

.product__button-order {
  padding: 18px 50px;
  font-size: 1.2rem;
  border: none;
  outline: none;
  border-radius: 15px;
  box-shadow: 0px 1px 5px 0px #abb3b6;
  margin-bottom: 60px;
  color: white;
  background-color: #28b47a;
}
.product__button-order:hover {
  box-shadow: none;
}

.product__home {
  text-decoration: none;
  margin-top: 40px;
  text-align: left;
  font-size: 25px;
  font-weight: 600;
}

.plate_type {
  background-color: rgb(214, 221, 221);
  padding: 10px;
  display: flex;
  border-radius: 15px;
  flex-direction: column;
}

.plate_type_heading {
  text-align: center;
  font-size: 23px;
  margin-bottom: 15px;
}
